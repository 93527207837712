import React from 'react'

const CloseIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 13 13">
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M7.935 6.176l4.093-4.06a1.234 1.234 0 000-1.756 1.26 1.26 0 00-1.772 0l-4.03 3.997L2.195.36a1.26 1.26 0 00-1.772 0 1.234 1.234 0 000 1.756l4.094 4.06-4.094 4.06a1.236 1.236 0 000 1.757 1.26 1.26 0 001.772 0l4.031-3.998 4.03 3.998c.245.242.565.363.886.363.321 0 .641-.121.886-.363a1.236 1.236 0 000-1.757l-4.093-4.06z"
      />
    </svg>
  )
}

export default CloseIcon
